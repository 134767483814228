<template>
  <div :class="$style['loginBox']">
    <div :class="$style['loginBox-con']">
      <div :class="$style['loginBox-logo']"></div>
      <div :class="$style['loginBox-text']">
        免翻墙无需任何工具
        <br/>高速稳定使用<span :class="$style['loginBox-pin']">Pinterest</span>
      </div>
    </div>
    <div :class="[$style['loginBox-line'], 'bbw-button']">
      <div :class="$style['loginBox-btn']" @click="toRegister">立即使用</div>
      <div :class="$style['loginBox-login']" @click="toLogin">登录</div>
    </div>

  </div>
</template>
<script>
import {
  AuthWeixinAuth,
  getDomain,
  getToken,
  getTokenCrossDomain,
  jumpPage,
  locationPush,
  PageMobileLoginPhone,
  PageMobileRegisterPhone,
  PageMobileWeixinAuth,
  PagePinliteMobileHome,
  getUrlParam,
  PageMobileLoginFastB,
  PageMobileRegisterPhoneB,
  isPinliteDomain
} from "bbw-common";

export default {
    name: "LoginBox",
    props:{
      // 如果本页时登录状态，跳转的页面
      loginRedirect: {
        type:String,
        required:false,
        default: PagePinliteMobileHome
      },
      // 点击登录注册，登录后跳转的页面
      loginUrl:{
        type: String,
        required:false,
        default: PagePinliteMobileHome
      },
      // 是否是sem页面
      isSem: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    beforeMount() {
      this.onEnter();
    },
    methods:{
        toLogin(){
          if(this.$store.state.isWechatBrowser) {
            this.loginWechat();
            return;
          }

          const inviteParams = getUrlParam('inviteCode') ? `&inviteCode=${getUrlParam('inviteCode')}` : ''
          locationPush( `${PageMobileLoginFastB}?target=pinlite&domain=${getDomain ().slice ( 1 )}&redirect=${encodeURIComponent( this.loginUrl || location.href)}${ this.isSem ? '&sem=1':'' }${inviteParams}`  )
        },
        toRegister(){
          if(this.$store.state.isWechatBrowser) {
            this.loginWechat();
            return;
          }
          const inviteParams = getUrlParam('inviteCode') ? `&inviteCode=${getUrlParam('inviteCode')}` : ''
          locationPush( `${PageMobileRegisterPhoneB}?target=pinlite&domain=${getDomain ().slice ( 1 )}&redirect=${encodeURIComponent( this.loginUrl ||  location.href )}${ this.isSem ? '&sem=1':'' }${inviteParams}` )
        },
        loginWechat() {
          let url;
          const inviteParams = getUrlParam('inviteCode') ? `&inviteCode=${getUrlParam('inviteCode')}` : ''
          if( isPinliteDomain(getDomain())){
            url = `https:${PageMobileWeixinAuth}?target=pinlite&domain=${getDomain ().slice ( 1 )}&redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
          }else{
            url = `https:${PageMobileWeixinAuth}?redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
          }
          const redirect = AuthWeixinAuth(url)
          jumpPage(this.$router, redirect)
        },
        async onEnter(){
          this.$store.commit('updateBrowserEnv');
          const token = getTokenCrossDomain();
          if ( !this.$store.getters[`UserInfo/userReady`] && token ){
            await this.$store.dispatch ( `UserInfo/FETCH_USER_INFO` ) ;
            if ( this.$store.getters[`UserInfo/userReady`] ){
              jumpPage(this.$router,this.loginRedirect);
            }
          }
        }
      }
    }
</script>
<style lang="scss" module src="./login-box.scss"></style>
