var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["loginBox"] }, [
    _c("div", { class: _vm.$style["loginBox-con"] }, [
      _c("div", { class: _vm.$style["loginBox-logo"] }),
      _vm._v(" "),
      _c("div", { class: _vm.$style["loginBox-text"] }, [
        _vm._v("\n      免翻墙无需任何工具\n      "),
        _c("br"),
        _vm._v("高速稳定使用"),
        _c("span", { class: _vm.$style["loginBox-pin"] }, [
          _vm._v("Pinterest"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { class: [_vm.$style["loginBox-line"], "bbw-button"] }, [
      _c(
        "div",
        { class: _vm.$style["loginBox-btn"], on: { click: _vm.toRegister } },
        [_vm._v("立即使用")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style["loginBox-login"], on: { click: _vm.toLogin } },
        [_vm._v("登录")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }