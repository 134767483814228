<template>
  <div>
    <sticky-bar  :direction="$attrs['direction']" :scroll-y="$attrs['scroll-y']" ref="stickBar"
                 :style="{height:100 / 750 * 116+'vw'}" :sticky-edge="116">
      <template v-slot:default="props">
        <div :class="$style.searchInputWrapper">
          <div :class="$style.inputWrapper">
            <img :class="$style['pinliteLogo']" :src="`${process.env.CDN_DZ_PATH}/p/pinilte_logo_w.svg`">
            <div :class="$style.inputBox">
              <input type="search" placeholder="即时搜索Pinterest图片" @focus="handleClickInput" v-touch:tap="()=>handleClickInput()" :class="$style.input"/>
            </div>
          </div>
        </div>
      </template>
    </sticky-bar>
    <login-box v-if="!$store.state.server" login-redirect='/home' :login-url="PagePinliteMobileHome"/>
    <scroll-view ref="scrollView" :style="{paddingTop:100/750*116 + 'vw', paddingBottom: 100/750*96+'vw'}">
      <vMasonry :class="$style.masonry" :scrollY="$attrs['scroll-y']" :refreshID="refreshID"
                :gapX="100/750*26" :gapY="100/750*26" :gridWidth="100/750*332" unit="vw" :items="items" :loadDistance="200" >
        <template v-slot:default="props">
          <grid-item-pinlite-unlogin :item="props.item.data" :itemIndex="props.item.order"
                                     @click="handleItemClick(props.item.data, props.item.order)"
          />
        </template>
      </vMasonry>
    </scroll-view>
  </div>
</template>
<script lang="ts">
  import {resizeItem} from "@scripts/utils";
  import GridItemPinliteUnlogin from "@components/grid-item-pinlite-unlogin/grid-item-pinlite-unlogin.vue";
  import LoginBox from "@components/login-box/login-box.vue";
  import { ScrollView, StickyBar } from "bbw-m-components/components";
  import {getDomain, isSearchMobile, locationPush, PageMobileRegisterPhone, PagePinliteMobileHome} from "bbw-common";
  import Vue from "vue";
  import vMasonry from "bbw-masonry";
  Vue.use(vMasonry);
  export default {
    name:"PagePinliteIndex",
    components: { GridItemPinliteUnlogin, ScrollView, StickyBar, LoginBox },
    asyncData: function( {store, route, context} ){
      if( process?.env?.VUE_ENV == 'server'){
        const res = context.cache.get('FETCH_PINLITE_INDEX_DATA');
        try {
          const items = res.items.map( ( val ) => {
            return resizeItem(val);
          })
          store.commit ( `setCache`, { key: `FETCH_PINLITE_INDEX_DATA`, value: items } ) ;
          return Promise.resolve(true);
        }catch (e) {
          store.commit ( `setCache`, { key: `FETCH_PINLITE_INDEX_DATA`, value: [] } ) ;
          store.commit ( `setDebug`, e) ;
          return Promise.reject( e );
        }
      }else{
        return Promise.resolve(store.state.cache["FETCH_PINLITE_INDEX_DATA"]);
      }
    },
    data(){
      return {
        items: this.$store.state.cache['FETCH_PINLITE_INDEX_DATA'],
        refreshID: 0,
        PagePinliteMobileHome
      }
    },
    mounted() {
      // 刷新滚动
      window.setTimeout( ()=>{
        this.$refs.scrollView.$emit("refresh")
      }, 300 )
    },
    methods: {
      handleItemClick(){
        this.toRegister();
      },
      handleClickInput(){
        this.toRegister();
      },
      /** 去注册 */
      toRegister() {
        locationPush(`${PageMobileRegisterPhone}?target=pinlite&redirect=${ this.loginUrl || encodeURIComponent(location.href)}`)
      }
    }
  }
</script>
<style lang=sass module src="./index.scss"></style>
