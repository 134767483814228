var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["loginBox"] }, [
    _c("div", { class: _vm.$style["loginBox-logo"] }),
    _vm._v(" "),
    _c("div", { class: _vm.$style["loginBox-text"] }, [
      _c("p", [_vm._v("免翻墙无需任何工具")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("高速稳定使用"),
        _c("span", { class: _vm.$style["loginBox-red"] }, [
          _vm._v("Pinterest"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style["loginBox-r"] }, [
      _c(
        "div",
        { class: _vm.$style["loginBox-btn"], on: { click: _vm.toRegister } },
        [_c("span", [_vm._v("立即使用")])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }