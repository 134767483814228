var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.item },
    [
      _c("div", { class: _vm.$style.grid, style: _vm.bgStyle }, [
        _c("a", {
          directives: [
            {
              name: "touch",
              rawName: "v-touch:tap.prevent",
              value: function () {
                return _vm.handleClick()
              },
              expression: "()=>handleClick()",
              arg: "tap",
              modifiers: { prevent: true },
            },
          ],
          class: _vm.$style.imgCover,
        }),
        _vm._v(" "),
        _c("img", {
          style: _vm.imgStyle,
          attrs: { src: "https:" + _vm.dataSrc, title: _vm.item.title },
          on: { load: _vm.handleLoad },
        }),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.desc }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "touch",
                rawName: "v-touch:tap",
                value: function () {
                  return _vm.handleMore()
                },
                expression: "()=>handleMore()",
                arg: "tap",
              },
            ],
            class: _vm.$style.btnMore,
          },
          [
            _c(
              "svg",
              {
                class: _vm.$style.iconMore,
                attrs: {
                  width: "16",
                  height: "4",
                  viewBox: "0 0 32 8",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M3.2 6.4a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4zm12.8 0A3.2 3.2 0 1116 0a3.2 3.2 0 010 6.4zm12.8 0a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isPop
        ? _c(
            "van-popup",
            {
              class: _vm.$style.popup,
              attrs: {
                transition: "van-slide-up",
                "overlay-class": _vm.$style.popOverlay,
                "get-container": "#app",
                position: "bottom",
                "safe-area-inset-bottom": true,
              },
              on: { closed: _vm.handleClosedPopup },
              model: {
                value: _vm.showPop,
                callback: function ($$v) {
                  _vm.showPop = $$v
                },
                expression: "showPop",
              },
            },
            [
              _c("h3", { class: _vm.$style.h3 }, [_vm._v("选项")]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  class: _vm.$style.close,
                  attrs: {
                    width: "34",
                    height: "34",
                    viewBox: "0 0 34 34",
                    xmlns: "http://www.w3.org/2000/svg",
                    "svg-inline": "",
                    role: "presentation",
                    focusable: "false",
                    tabindex: "-1",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M32.313 27.641c1.557 1.557 1.557 3.634 0 4.672-1.557 1.557-3.634 1.557-4.672 0L17.26 21.931 6.359 32.832c-1.557 1.557-3.634 1.557-5.191 0s-1.557-4.153 0-5.19l10.9-10.902L1.688 6.36C.13 5.32.13 3.244 1.687 1.687 3.244.13 5.321.13 6.359 1.687L16.74 12.069 27.641 1.168c1.557-1.557 3.634-1.557 5.191 0s1.557 3.634 0 5.19l-10.9 10.902L32.312 27.64z",
                      fill: "#757677",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "touch",
                        rawName: "v-touch:tap",
                        value: function () {
                          return _vm.handleFav()
                        },
                        expression: "()=>handleFav()",
                        arg: "tap",
                      },
                    ],
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          xmlns: "http://www.w3.org/2000/svg",
                          "svg-inline": "",
                          role: "presentation",
                          focusable: "false",
                          tabindex: "-1",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M14 10h8a2 2 0 110 4h-8v8a2 2 0 11-4 0v-8H2a2 2 0 110-4h8V2a2 2 0 114 0v8z",
                            "fill-rule": "evenodd",
                          },
                        }),
                      ]
                    ),
                    _vm._v("\n        收藏\n      "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "touch",
                        rawName: "v-touch:tap",
                        value: function () {
                          return _vm.handleDownload()
                        },
                        expression: "()=>handleDownload()",
                        arg: "tap",
                      },
                    ],
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "22",
                          viewBox: "0 0 24 22",
                          xmlns: "http://www.w3.org/2000/svg",
                          "svg-inline": "",
                          role: "presentation",
                          focusable: "false",
                          tabindex: "-1",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M21.333 12.395v6.5H2.667v-6.5H0v9.1h24v-9.1h-2.667zM12 15.5l5.333-6.25h-4V.5h-2.666v8.75h-4L12 15.5z",
                            "fill-rule": "nonzero",
                          },
                        }),
                      ]
                    ),
                    _vm._v("\n        下载\n      "),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }