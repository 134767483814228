<template>
  <div>
    <sticky-bar  :direction="$attrs['direction']" :scroll-y="$attrs['scroll-y']" ref="stickBar"
                 :style="{height:100 / 750 * 116+'vw'}" :sticky-edge="116">
      <template v-slot:default="props">
        <div :class="$style.searchInputWrapper">
          <div :class="$style.inputWrapper">
            <img :class="$style['pinliteLogo']" :src="`${process.env.CDN_DZ_PATH}/p/pinilte_logo_w.svg`">
            <div :class="$style.inputBox">
              <input type="search" placeholder="即时搜索Pinterest图片" @focus="handleClickInput" v-touch:tap="()=>handleClickInput()" :class="$style.input"/>
            </div>
          </div>
        </div>
      </template>
    </sticky-bar>
    <login-box v-if="!$store.state.server" login-redirect='/tupian' :isSem="false" :login-url="PagePinliteMobileHome"  @tap="toRegister"/>
    <scroll-view ref="scrollView" :style="{paddingTop:100/750*116 + 'vw', paddingBottom: 100/750*96+'vw'}">
      <vMasonry :class="$style.masonry" :scrollY="$attrs['scroll-y']" :refreshID="refreshID"
                :gapX="100/750*26" :gapY="100/750*26" :gridWidth="100/750*332" unit="vw" :items="items" :loadDistance="200" >
        <template v-slot:default="props">
          <grid-item-pinlite-unlogin :item="props.item.data" :itemIndex="props.item.order"
                                     @click="handleItemClick(props.item.data, props.item.order)"
          />
        </template>
      </vMasonry>
      <div :class="$style.searchEnd">
        <Loading v-if="$store.state.server"/>
      </div>
    </scroll-view>
  </div>
</template>
<script lang="ts">
  import {resizeItem} from "@scripts/utils";
  import { ScrollView, StickyBar, Loading, GridItemPinliteUnlogin, PinliteLoginBox as LoginBox } from "bbw-m-components/components";
  import {
    AuthWeixinAuth,
    getDomain, isPinliteDomain, jumpPage,
    locationPush,
    PageMobileRegisterPhone,
    PageMobileRegisterPhoneB, PageMobileWeixinAuth,PageMobileThirdLogin,PageMobileLoginPhoneB,
    PagePinliteMobileHome,
    tracertUTM,crossDomainMessage,checkIsIncognito,
    cookieGet
  } from "bbw-common";
  import Vue from "vue";
  import vMasonry from "bbw-masonry";
  import qs from "qs";
  Vue.use(vMasonry);
  export default {
      name:"PagePinliteIndex",
      components: { GridItemPinliteUnlogin, ScrollView, StickyBar, LoginBox, Loading },
      asyncData: function( {store, route, context} ){
          if( process?.env?.VUE_ENV == 'server'){
              const res = context.cache.get('FETCH_PINLITE_INDEX_DATA');
              try {
                  const items = res.items.map( ( val ) => {
                      return resizeItem(val);
                  })
                  store.commit ( `setCache`, { key: `FETCH_PINLITE_INDEX_DATA`, value: items } ) ;
                  return Promise.resolve(true);
              }catch (e) {
                  store.commit ( `setCache`, { key: `FETCH_PINLITE_INDEX_DATA`, value: [] } ) ;
                  return Promise.reject( e );
              }
          }else{
              return Promise.resolve(store.state.cache["FETCH_PINLITE_INDEX_DATA"]);
          }
      },
      data(){
          return {
              items: this.$store.state.cache['FETCH_PINLITE_INDEX_DATA'],
              refreshID: 0,
              isSem:false,
              PagePinliteMobileHome,
              cookieStr:``
          }
      },
      mounted() {

          tracertUTM();
          this.isSem = !!this.$route.query['utm_source'];
          // 刷新滚动
          window.setTimeout( ()=>{
              this.$refs.scrollView.$emit("refresh")
          }, 300 )
      },
      methods: {
          handleItemClick(){
              this.toRegister();
          },
          handleClickInput(){
              this.toRegister();
          },
          getParams() {
            const domain = getDomain().slice(1);
            const pinlite_domain = this.$route.query.domain || (isPinliteDomain(domain) ? domain : process.env.PINLITE_DOMAIN);
            const param = Object.assign(this.$route.query, {
              domain: pinlite_domain,
              target: 'pinlite',
              sem: 1
            });
            return param;
          },
          /** 去注册 */
          async toRegister() {
            if(this.$store.state.isWechatBrowser) {
              this.loginWechat();
              return;
            }
            const param = this.getParams();
            locationPush(`${PageMobileThirdLogin}?${qs.stringify(param)}`)
            /*let param = this.getParams();
            const [err, res] = await cookieGet('loginInfo')
            const [err1, res1] = await cookieGet('crossLogin')
            console.log(res, '///////res')
            console.log(res1,' ///////res1');
            if(!err || !err1) {
              this.cookieStr = res || res1;
            } else {
              this.cookieStr = ``
            }
            if(this.cookieStr){
              const obj = JSON.parse(this.cookieStr);
              console.log(obj)
              if(obj.phone && obj.password){
                locationPush(`${PageMobileThirdLogin}?${qs.stringify(param)}`)
              }else{
                locationPush(`${PageMobileLoginPhoneB}?${qs.stringify(param)}`)
              }
            }else{
              locationPush(`${PageMobileLoginPhoneB}?${qs.stringify(param)}`)
            }*/
          },
          loginWechat() {
            const param = this.getParams();
            const url = `https:${PageMobileWeixinAuth}?${qs.stringify(param)}` ;
            // if( isPinliteDomain(getDomain())){
            //   url = `https:${PageMobileWeixinAuth}?target=pinlite&domain=${pinlite_domain}&redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
            // }else{
            //   url = `https:${PageMobileWeixinAuth}?redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
            // }
            const redirect = AuthWeixinAuth(url)
            jumpPage(this.$router, redirect)
          }
      }
  }
</script>
<style lang=sass module src="./index.scss"></style>
