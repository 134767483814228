import { ResultItem } from "bbw-common" ;

/** 设置瀑布流显示尺寸(最高500，最小100) **/
export const resizeItem = ( item:ResultItem ):ResultItem => {
    const h = 100 / 750 * 332 / item.w * item.h ;
    item.dw = 100 / 750 * 332 ;
    // item.dh = h + 24 ;
    // 8 底部间距
    item.dh = h ;
    item.dh = Math.max ( Math.min ( 100 / 750 * 800, item.dh ), 100 / 750 * 100 ) ;
    return item ;
} ;
