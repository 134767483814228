<template>
  <div :class="$style['loginBox']">
    <div :class="$style['loginBox-logo']"></div>
    <div :class="$style['loginBox-text']">
      <p>免翻墙无需任何工具</p>
      <p>高速稳定使用<span :class="$style['loginBox-red']">Pinterest</span></p>
    </div>
    <div :class="$style['loginBox-r']">
      <div :class="$style['loginBox-btn']" @click="toRegister">
        <span>立即使用</span>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    AuthWeixinAuth,
    getDomain,
    getTokenCrossDomain,
    jumpPage,
    PageMobileWeixinAuth,
    PagePinliteMobileHome,
    getUrlParam,
    isPinliteDomain,
    getPinliteAuthUrl
  } from "bbw-common";

export default {
    name: "LoginBox",
    props:{
      // 如果本页时登录状态，跳转的页面
      loginRedirect: {
        type:String,
        required:false,
        default: PagePinliteMobileHome
      },
      // 点击登录注册，登录后跳转的页面
      loginUrl:{
        type: String,
        required:false,
        default: PagePinliteMobileHome
      },
      // 是否是sem页面
      isSem: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    beforeMount() {
      this.onEnter();
    },
    methods:{
        toLogin(){
          this.$emit("tap")
        },
        toRegister(){
          this.$emit("tap")
        },
        loginWechat() {
          let url;
          const inviteParams = getUrlParam('inviteCode') ? `&inviteCode=${getUrlParam('inviteCode')}` : ''
          const domain = getDomain().slice(1);
          const pinlite_domain = this.$route.query.domain || ( isPinliteDomain(domain) ? domain : process.env.PINLITE_DOMAIN) ;
          url = `https:${PageMobileWeixinAuth}?target=pinlite&domain=${pinlite_domain}&redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
          // if( isPinliteDomain(getDomain())){
          //   url = `https:${PageMobileWeixinAuth}?target=pinlite&domain=${pinlite_domain}&redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
          // }else{
          //   url = `https:${PageMobileWeixinAuth}?redirect=${ this.loginUrl || window.location.href}${ this.isSem ? '&sem=1':'' }${inviteParams}` ;
          // }
          const redirect = AuthWeixinAuth(url)
          jumpPage(this.$router, redirect)
        },
        async onEnter(){
          debugger
          this.$store.commit('updateBrowserEnv');
          const token = await getTokenCrossDomain();
          if ( !this.$store.getters[`UserInfo/userReady`] && token ){
            await this.$store.dispatch ( `UserInfo/FETCH_USER_INFO` ) ;
            // await this.$store.dispatch("UserInfo/FETCH_USER_TEAM")
            if ( this.$store.getters[`UserInfo/userReady`] ){
              await this.$store.dispatch ( `PinUser/checkPinliteDomain`, { needCache: true, type: `m` } ) ;
              const redirect = window.location.hostname.match(/pinlite/)
                      ? this.loginRedirect
                      : getPinliteAuthUrl( this.$store,this.loginRedirect, "m" ) ;
              jumpPage(this.$router,redirect);
            }
          }
        }
      }
    }
</script>
<style lang="scss" module src="./pinlite-login-box.scss"></style>
