var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sticky-bar", {
        ref: "stickBar",
        style: { height: (100 / 750) * 116 + "vw" },
        attrs: {
          direction: _vm.$attrs["direction"],
          "scroll-y": _vm.$attrs["scroll-y"],
          "sticky-edge": 116,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("div", { class: _vm.$style.searchInputWrapper }, [
                  _c("div", { class: _vm.$style.inputWrapper }, [
                    _c("img", {
                      class: _vm.$style["pinliteLogo"],
                      attrs: {
                        src:
                          _vm.process.env.CDN_DZ_PATH + "/p/pinilte_logo_w.svg",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.inputBox }, [
                      _c("input", {
                        directives: [
                          {
                            name: "touch",
                            rawName: "v-touch:tap",
                            value: function () {
                              return _vm.handleClickInput()
                            },
                            expression: "()=>handleClickInput()",
                            arg: "tap",
                          },
                        ],
                        class: _vm.$style.input,
                        attrs: {
                          type: "search",
                          placeholder: "即时搜索Pinterest图片",
                        },
                        on: { focus: _vm.handleClickInput },
                      }),
                    ]),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      !_vm.$store.state.server
        ? _c("login-box", {
            attrs: {
              "login-redirect": "/tupian",
              isSem: false,
              "login-url": _vm.PagePinliteMobileHome,
            },
            on: { tap: _vm.toRegister },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "scroll-view",
        {
          ref: "scrollView",
          style: {
            paddingTop: (100 / 750) * 116 + "vw",
            paddingBottom: (100 / 750) * 96 + "vw",
          },
        },
        [
          _c("vMasonry", {
            class: _vm.$style.masonry,
            attrs: {
              scrollY: _vm.$attrs["scroll-y"],
              refreshID: _vm.refreshID,
              gapX: (100 / 750) * 26,
              gapY: (100 / 750) * 26,
              gridWidth: (100 / 750) * 332,
              unit: "vw",
              items: _vm.items,
              loadDistance: 200,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("grid-item-pinlite-unlogin", {
                      attrs: {
                        item: props.item.data,
                        itemIndex: props.item.order,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleItemClick(
                            props.item.data,
                            props.item.order
                          )
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.searchEnd },
            [_vm.$store.state.server ? _c("Loading") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }