<template>
  <div :class="$style.item">
    <div :class="$style.grid"  :style="bgStyle">
        <a :class="$style.imgCover" v-touch:tap.prevent="()=>handleClick()" />
        <img :src="'https:'+dataSrc"  :title="item.title" :style="imgStyle" @load="handleLoad" />
    </div>
    <div :class="$style.desc">
      <div :class="$style.btnMore" v-touch:tap="()=>handleMore()">
        <svg width="16" height="4" viewBox="0 0 32 8" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="$style.iconMore" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.2 6.4a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4zm12.8 0A3.2 3.2 0 1116 0a3.2 3.2 0 010 6.4zm12.8 0a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4z" fill-rule="evenodd"/></svg>
      </div>
    </div>

    <van-popup :class="$style.popup" v-if="isPop" v-model="showPop"  transition="van-slide-up"
               :overlay-class="$style.popOverlay" get-container="#app"
               :position="`bottom`" :safe-area-inset-bottom="true"
               @closed="handleClosedPopup"
    >
      <h3 :class="$style.h3">选项</h3>
      <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="$style.close" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M32.313 27.641c1.557 1.557 1.557 3.634 0 4.672-1.557 1.557-3.634 1.557-4.672 0L17.26 21.931 6.359 32.832c-1.557 1.557-3.634 1.557-5.191 0s-1.557-4.153 0-5.19l10.9-10.902L1.688 6.36C.13 5.32.13 3.244 1.687 1.687 3.244.13 5.321.13 6.359 1.687L16.74 12.069 27.641 1.168c1.557-1.557 3.634-1.557 5.191 0s1.557 3.634 0 5.19l-10.9 10.902L32.312 27.64z" fill="#757677" fill-rule="evenodd"/></svg>
      <ul>
        <li v-touch:tap="()=>handleFav()">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14 10h8a2 2 0 110 4h-8v8a2 2 0 11-4 0v-8H2a2 2 0 110-4h8V2a2 2 0 114 0v8z" fill-rule="evenodd"/></svg>
          收藏
        </li>
        <li v-touch:tap="()=>handleDownload()">
          <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.333 12.395v6.5H2.667v-6.5H0v9.1h24v-9.1h-2.667zM12 15.5l5.333-6.25h-4V.5h-2.666v8.75h-4L12 15.5z" fill-rule="nonzero"/></svg>
          下载
        </li>
      </ul>
    </van-popup>
  </div>
</template>
<script lang="ts">
    import {emptyImg, PinliteItem, ResultItem, SearchProxy, toRelativeProtocol} from "bbw-common";
    import Vue from 'vue';
    import { Popup } from 'vant';
    Vue.use(Popup);
  export default {
      name:"GridItemPinlite",
      props:{
          /** 背景色 */
          color:{
              type: String,
              required: false,
              default: "#f0f0f0"
          },
          /** pinliteItem */
          item:{
              type:Object,
              required:true
          },
          itemIndex:{
              type:Number,
              required:false,
              default: 0
          }
      },
      mounted() {
          // this.multiSrc = this.getMultiSrc(this.item);
      },
      data(){
          return {
              /** 图片URL */
              dataSrc: this.item.url,
              /** 多分辨率图片URL */
              multiSrc: `${emptyImg} 1x, ${emptyImg} 2x`,
              /** 图片是否已加载 */
              loaded: false,
              /** 是否显示更多底部弹窗 */
              showPop: false,
              /** 是否显示弹窗节点，用于不显示弹窗时清空弹窗DOM节点 */
              isPop:false
          }
      },
      methods:{
          getMultiSrc( item ){
              const url_S = SearchProxy.getImgUrl( (item as unknown as ResultItem).url, null, item );
              const url_L = SearchProxy.getImgUrl( item.url, null, item );
              return `${url_S} 1x, ${url_S} 2x, ${url_L} 3x`;
          },
          handleLoad( e:Event){
              // @ts-ignore
              if( e.target.src === emptyImg) {
                  return;
              }
              this.loaded = true;
          },
          handleFav(){

          },
          handleMore(){

          },
          handleClosedPopup(){

          },
          handleClick(){
              this.$emit("click")
          },
          async handleDownload(){

          }
      },
      computed:{
          bgStyle(){
              return {
                  background: this.loaded ? `#f0f0f0` : this.item.color
              }
          },
          imgStyle(){
              const style:any = {};
              if(this.item.w>=236){
                  style.width = '100%';
              }
              if( this.loaded === false && !this.$store.state.server ){
                  style.opacity = 0;
              }else{
                  style.opacity = 1;
              }
              return style;
          }
      },
      watch:{
          item( newValue, oldValue ){
              if( newValue.url !== oldValue.url ){
                  this.loaded = false;
                  this.dataSrc = emptyImg;
                  this.multiSrc = `${emptyImg} 1x, ${emptyImg} 2x`;
                  this.$nextTick( ()=> {
                      this.dataSrc = newValue.url;
                  })
              }
          }
      }
  }
</script>
<style module lang=scss src="./grid-item-pinlite-unlogin.scss"></style>
